import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse } from 'client/types.gen';
import dayjs from 'dayjs';
import { DESIGN_MANUFACTURE_COMBINATION } from 'pages/OrderDetail/constants/manufactureDesignMap';

import { usePrintRx } from '../../../../shared/hooks/usePrintRx';
import { LowerElement, OrderPrintRx } from './PrintRx';

export const OrderInformation = ({ orderDetail }: { orderDetail: OrderDetailResponse }) => {
  const { t } = useTranslation();
  const { upperRef, lowerRef, handleGeneratePDF } = usePrintRx();

  const { orderNumber, designManufactureCombination, orderedAt, designConfirmProcessIncluded } = orderDetail;
  const orderInfoConfig = [
    { label: 'patient_page_info.title_order_number', value: orderNumber },
    {
      label: 'patient_page_info.title_order_range',
      value: t(DESIGN_MANUFACTURE_COMBINATION[designManufactureCombination]),
    },
    { label: 'patient_page_info.title_order_date', value: dayjs(orderedAt ?? new Date()).format('YYYY-MM-DD') },
    {
      label: 'patient_page_info.title_design_confirm_process',
      value: t(`patient_page_info.${designConfirmProcessIncluded ? 'include' : 'exclude'}`),
    },
  ];

  return (
    <>
      <Stack
        sx={{
          p: '16px 24px ',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: theme.palette.grey['100'],
          borderRadius: '8px',
        }}
      >
        <Stack>
          {orderInfoConfig.map(({ label, value }) => (
            <Stack key={label} sx={{ flexDirection: 'row', gap: '4px', color: theme.palette.text.secondary }}>
              <Typography variant={'Subtitle_Semibold14'}>{t(label)}</Typography>
              <Typography variant={'Subtitle_Medium14'}>{value} </Typography>
            </Stack>
          ))}
        </Stack>
        <Button
          size={'30'}
          color={'grey'}
          variant={'outlined'}
          endIcon={<Ricon icon={'ri-file-text-line'} />}
          sx={{ minWidth: 'fit-content' }}
          onClick={handleGeneratePDF}
        >
          {t('patient_page_info.btn_print_rx')}
        </Button>
      </Stack>
      <OrderPrintRx rxPrintRef={upperRef} userOrder={orderDetail} />
      <LowerElement rxPrintRef={lowerRef} userOrder={orderDetail} />
    </>
  );
};
