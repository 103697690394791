import React, { ComponentType, lazy } from 'react';
import withAsyncBoundary from 'hoc/withAsyncBoundary';
import { LoadingCenter } from 'shared/ui/loading/LoadingCenter';

import { ErrorPage } from '../../pages/Error/ErrorPage';

const OrderDetail = lazy(() => import('pages/OrderDetail/OrderDetail'));
const CostInfo = lazy(() => import('pages/CostInfo/CostInfo'));
const History = lazy(() => import('pages/History/History'));
const Operation = lazy(() => import('pages/Operation/Operation'));
const Organization = lazy(() => import('pages/Organization/Organization'));
const Sales = lazy(() => import('pages/Sales/Sales'));

const pageAsyncRoute = (Component: ComponentType) =>
  withAsyncBoundary(Component, {
    pendingFallback: <LoadingCenter />,
    rejectedFallback: <ErrorPage />,
  });

const AsyncOrderDetail = pageAsyncRoute(OrderDetail);
const AsyncCostInfo = pageAsyncRoute(CostInfo);
const AsyncHistory = pageAsyncRoute(History);
const AsyncOperation = pageAsyncRoute(Operation);
const AsyncOrganization = pageAsyncRoute(Organization);
const AsyncSales = pageAsyncRoute(Sales);

export { AsyncCostInfo, AsyncHistory, AsyncOperation, AsyncOrderDetail, AsyncOrganization, AsyncSales };
