import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Ricon, TextField } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { useOrderState } from 'pages/MyOrderList/atoms/useOrderState';

import { getCurrentOrderStatus } from '../../../../shared/utils/getOrderStatusConfig';

export const SearchField = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { updateOrderQueryParams } = useOrderState();
  const { t } = useTranslation();
  const currentOrderStatus = getCurrentOrderStatus();

  useEffect(() => {
    if (searchRef.current) {
      updateOrderQueryParams({ searchFilterType: 'PATIENT_NAME', searchKeyword: '' });
      searchRef.current.value = '';
    }
    setIsSearchActive(false);
  }, [currentOrderStatus]);

  const handleSearchClear = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
      setIsSearchActive(false);
      updateOrderQueryParams({ searchKeyword: '' });
    }
  };

  const handleSearchSubmit = () => {
    if (searchRef.current) {
      updateOrderQueryParams({
        searchKeyword: searchRef.current.value,
      });
    }
  };
  const handleInputChange = () => {
    if (searchRef.current) {
      setIsSearchActive(searchRef.current.value.length > 0);
    }
  };

  return (
    <TextField
      inputRef={searchRef}
      placeholder={t('search.placeholder') as string}
      onKeyDown={(e) => {
        e.key === 'Enter' && handleSearchSubmit();
      }}
      InputProps={{
        startAdornment: isSearchActive && (
          <IconButton size={'36'} color={'grey800'} variant={'transparent'} onClick={handleSearchClear}>
            <Ricon
              icon="ri-arrow-left-line"
              svgProps={{
                fill: grey[800],
              }}
            />
          </IconButton>
        ),
        endAdornment: (
          <IconButton size={'36'} color={'grey800'} variant={'transparent'} onClick={handleSearchSubmit}>
            <Ricon icon={'ri-search-line'} />
          </IconButton>
        ),
      }}
      onChange={handleInputChange}
      sx={{
        '& .MuiOutlinedInput-input': {
          padding: 0,
          marginLeft: isSearchActive ? '4px' : '16px',
          marginRight: '8px',
        },
      }}
    ></TextField>
  );
};
