import { useRecoilState } from 'recoil';
import { userRoleState } from 'shared/atoms/role/atom';

export const usePermission = () => {
  const [userRoleConfig, setUserRoleConfig] = useRecoilState(userRoleState);
  const role = userRoleConfig.role;

  const accessibleClientPaths = userRoleConfig.permissions.map(({ clientPath }) => clientPath).filter((path) => path);
  const accessiblePermissions = userRoleConfig.permissions.map((snb) => snb?.permissionId);

  return { role, userRoleConfig, setUserRoleConfig, accessibleClientPaths, accessiblePermissions };
};
