import { useCallback, useEffect } from 'react';
import { useResponseSuccessHandler } from '@imago-cloud/auth-client'; // Assuming a hook to manage user permissions
import { HTTPError } from 'ky';

import { kyInstance, kyServiceInstance } from '../../api/kyInstance';
import { ResponseDtoUserGetResponse } from '../../client';
import { ROLE } from '../../constants/role';
import { usePermission } from '../../shared/hooks/usePermission';
import { useAuthState } from './store/useAuthState';

export const useAuthHoc = () => {
  const { authInfo, setIsAuthLoading, setIsAuthorized, setIsErrorPage } = useAuthState();
  const { isAuthorized, isAuthLoading, isErrorPage } = authInfo;

  const { setUserRoleConfig } = usePermission();
  const isCompleteProfileDialogOpen = false;

  const handleAuthFail = useCallback(() => setIsErrorPage(true), []);
  const { handleResponseSuccess } = useResponseSuccessHandler();

  const initializeAccessToken = useCallback(async () => {
    try {
      kyServiceInstance.onResponseSuccess = handleResponseSuccess;
      await kyServiceInstance.updateAccessToken();
    } catch (error) {
      window.location.replace(process.env.REACT_APP_ACCOUNTS_CLIENT_URL!);
    } finally {
      kyServiceInstance.onAuthenticationFail = handleAuthFail; // *** token 관련 에러 따로 잡기 ***
    }
  }, [handleAuthFail]);

  const fetchUserData = async (): Promise<ResponseDtoUserGetResponse | null> => {
    try {
      const response = await kyInstance.get('connect/users/me', {});
      return await response.json();
    } catch (error) {
      if (error instanceof HTTPError) {
        const errorStatus = error.response?.status;
        const errorData = await error.response.json();

        if (errorStatus === 400) {
          if (errorData?.errorCode === 'CONNECT:USER_NOT_FOUND') {
            setIsAuthorized(false);
          } else {
            setIsErrorPage(true);
          }
        } else if (errorStatus === 403) {
          if (errorData.errorCode === 'CONNECT:USER_TENANT_NOT_ALLOWED') {
            window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
          }
        }
      }

      return null;
    }
  };

  const checkUserAndPermissions = useCallback(async () => {
    try {
      const response = await fetchUserData();
      const data = response?.data;
      if (!data) return;
      if (data.permissions.length === 0) {
        window.location.reload();
        return;
      }

      const hasBackofficeQcRole = data.roles.includes('ci_medical:connect:backoffice:qc');
      const redirectUrl = hasBackofficeQcRole ? process.env.REACT_APP_CONNECT_BO_CLIENT_URL : null;
      if (redirectUrl) {
        window.location.replace(redirectUrl as string);
        return;
      }

      const connectRole = data.roles.find((role) => role !== 'ci_medical:user') as keyof typeof ROLE;
      setUserRoleConfig({ role: connectRole, permissions: data.permissions });
    } catch (err) {
      throw err;
    } finally {
      setIsAuthLoading(false);
    }
  }, [isCompleteProfileDialogOpen, setUserRoleConfig]);

  useEffect(() => {
    const initialize = async () => {
      setIsAuthLoading(true);
      await initializeAccessToken();
      await checkUserAndPermissions();
    };
    initialize();
  }, []);

  return { isAuthLoading, isAuthorized, isErrorPage };
};
