export const ROUTES = {
  index: '/',
  DASHBOARD_ROUTER: {
    root: '/dashboard',
    placedDashboard: 'placed',
    receiveDashboard: 'received',
  },
  ORDER_ROUTER: {
    root: '/order',
    placedOrderList: 'placed-orders',
    receivedOrderList: 'received-orders',
    newOrder: 'new',
    placedOrderDetail: 'placed-order-detail/:patientNumber',
    receiveOrderDetail: 'received-order-detail/:patientNumber',

    // trash: 'trash',
    remake: 'remake/:patientNumber',
  },
  MY_ORGANIZATION_ROUTER: {
    root: '/my-organization',
    organization: 'organization',
    operation: 'operation',
    sales: 'sales',
    history: 'history',
  },
  COST_INFO_ROUTER: {
    root: '/cost-information',
  },
};
