import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ACTION_PATHS, ORDER_DETAIL_PATHS } from 'shared/constants/apiUrl';
import { queryClient } from 'shared/providers';
import { OrderRole } from 'shared/types/role';
import { getCurrentOrderStatus } from 'shared/utils/getOrderStatusConfig';
import { useTopAlert } from 'store';

import {
  GetOrderDetailResponse,
  GetOrders1Data,
  GetOrdersData,
  OrderCreateRequest,
  OrderShippingCreateRequest,
  RateOrderItemRequest,
  RemakeOrderCreateRequest,
  ResponseDtoListOrderRemakeHistory,
  ResponseDtoListOrganizationGroup,
  ResponseDtoOrder,
  ResponseDtoOrderStatusResponse,
  ResponseDtoPagePartnerLabOrderResponse,
  ResponseDtoUnit,
  ResponsePageOrderResponse,
} from '../client';
import { STATUS_LABELS } from '../pages/MyOrderList/constants/menuOptions';
import { kyInstance } from './kyInstance';

// order list query
const PAGE_SIZE = 15;

export const orderKeys = {
  all: ['orderData'] as const,
  orderDetail: (orderId: string, status: OrderRole) =>
    [...orderKeys.all, `${status === 'orderer' ? 'placed-order-detail' : 'received-order-detail'}`, orderId] as const,
  lists: (status: OrderRole) =>
    [...orderKeys.all, `${status === 'orderer' ? 'placed-orders' : 'received-orders'}`] as const, // todo: searchParams 추가
  remakeOrderDetail: (orderId: string) => [...orderKeys.all, 'remakeDetail', orderId] as const,
  remakeHistory: (orderId: string, isPartnerLab?: boolean) =>
    [...orderKeys.all, 'remakeHistory', orderId, ...(isPartnerLab ? ['isPartnerLab'] : [])] as const,
};

export const useOrderInfiniteQuery = (orderQueryParams: GetOrdersData | GetOrders1Data) => {
  const currentOrderStatus = getCurrentOrderStatus();

  const formatSearchParams = (params: Record<string, any>) => {
    return Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
  };

  return useInfiniteQuery<ResponsePageOrderResponse | ResponseDtoPagePartnerLabOrderResponse>({
    queryKey: [orderKeys.lists(currentOrderStatus), orderQueryParams],
    queryFn: async ({ pageParam = 1 }: QueryFunctionContext) => {
      return kyInstance
        .get(`connect/orders${currentOrderStatus === 'receiver' ? '/partner-lab' : ''}`, {
          searchParams: {
            //필요한 검색 조건들
            page: pageParam as number,
            size: PAGE_SIZE,
            ...formatSearchParams(orderQueryParams),
          },
        })
        .json();
    },
    refetchOnWindowFocus: true,
    getNextPageParam: (lastPage) => {
      const { last, number } = lastPage.data || {};
      if (last || number === undefined) {
        return undefined;
      }
      return number + 1;
    },
    initialPageParam: 1,
  });
};

//placedOrderDetail
export const useOrderDetailQuery = (orderId: string) => {
  const currentOrderStatus = getCurrentOrderStatus();
  return useSuspenseQuery<GetOrderDetailResponse>({
    queryKey: orderKeys.orderDetail(orderId, currentOrderStatus),
    queryFn: async () =>
      kyInstance
        .get(ORDER_DETAIL_PATHS[currentOrderStatus as keyof typeof ORDER_DETAIL_PATHS](orderId))
        .json<GetOrderDetailResponse>(),
    refetchOnWindowFocus: true,
  });
};

export type MutationVariables = {
  status: keyof typeof ACTION_PATHS;
  body?: any;
};

export const useOrderStatusChangeMutation = (orderId: string) => {
  const currentOrderStatus = getCurrentOrderStatus();
  const { setTopAlert } = useTopAlert();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: [orderKeys.all, orderId],
    mutationFn: async ({ status, body }: MutationVariables) => {
      const url = `connect/orders/${ACTION_PATHS[status](orderId)}${body?.chattingChannelUrl ? `?chattingChannelUrl=${body.chattingChannelUrl}` : ''}`;
      const options = body ? { json: { ...body, chattingChannelUrl: undefined } } : undefined;

      const result: ResponseDtoOrderStatusResponse = await kyInstance.patch(url, options).json();
      return { newStatus: result.data?.newStatus };
    },
    onSuccess: async ({ newStatus }) => {
      await queryClient.invalidateQueries({ queryKey: orderKeys.orderDetail(orderId, currentOrderStatus) });

      setTopAlert({
        open: true,
        variant: 'filled',
        severity: 'success',
        description: t(`alert_order_updated.body`, {
          variable: newStatus ? t(STATUS_LABELS[newStatus] ?? '') : '',
        }),
      });
    },
  });
};

// order submit query
export const useOrderCreateMutation = () => {
  return useMutation({
    mutationFn: async (orderData: OrderCreateRequest): Promise<ResponseDtoOrder> =>
      kyInstance
        .post('connect/orders', {
          json: orderData,
        })
        .json(),
  });
};

//new order 시 마지막 단계
export const useOrderSubmitMutation = () => {
  return useMutation({
    mutationFn: async (shippingData: OrderShippingCreateRequest): Promise<ResponseDtoUnit> =>
      kyInstance
        .post('connect/orders/shipping', {
          json: shippingData,
        })
        .json(),
  });
};

export const useOrderRateMutation = (orderId: string) => {
  const { setTopAlert } = useTopAlert();

  return useMutation({
    mutationFn: (data: RateOrderItemRequest) =>
      kyInstance
        .post(`connect/orders/${orderId}/ratings`, {
          json: data,
        })
        .json(),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: orderKeys.orderDetail(orderId, 'orderer') });
      setTopAlert({
        open: true,
        variant: 'filled',
        severity: 'success',
        description: 'Updated successfully',
        // description: `${status} changed`,
      });
    },
  });
};

//---------------------------- remake
export const useRemakeOrderDetailQuery = (orderId: string) =>
  useQuery({
    queryKey: orderKeys.remakeOrderDetail(orderId),
    queryFn: (): Promise<ResponseDtoOrder> => kyInstance.get(`connect/orders/${orderId}/remake`).json(),
  });

export const useRemakeCreateMutation = () => {
  return useMutation({
    mutationFn: async (orderData: RemakeOrderCreateRequest): Promise<ResponseDtoOrder> =>
      kyInstance
        .post('connect/orders/remake', {
          json: orderData,
        })
        .json(),
  });
};

export const useRemakeHistoryQuery = (orderId: string, isPartnerLab?: boolean) => {
  return useQuery({
    queryKey: orderKeys.remakeHistory(orderId, isPartnerLab),
    queryFn: (): Promise<ResponseDtoListOrderRemakeHistory> =>
      kyInstance.get(`connect/orders/${isPartnerLab ? 'partner-lab/' : ''}${orderId}/remake/history`).json(),
    enabled: !!orderId,
  });
};

export const useOrganizationListQuery = () => {
  return useQuery({
    queryKey: ['organizationList'],
    queryFn: (): Promise<ResponseDtoListOrganizationGroup> =>
      kyInstance.get(`connect/orders/partner-lab/organizations`).json(),
  });
};

const patchChattingUrlOnOrder = (orderId: string, chattingChannelUrl: string) =>
  kyInstance.patch(`connect/orders/${orderId}/chatting`, { json: { chattingChannelUrl } }).json();

export const useAddChattingUrlOnOrder = () => {
  return useMutation({
    mutationFn: ({ orderId, chattingChannelUrl }: { orderId: string; chattingChannelUrl: string }) =>
      patchChattingUrlOnOrder(orderId, chattingChannelUrl),
  });
};
