import { UseQueryResult } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { theme } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse, status } from 'client/types.gen';
import SplitHalfLayout from 'pages/NewOrder/components/SplitHalfLayout';
import { ActionArea } from 'pages/OrderDetail/components/ActionArea';
import { InformationArea } from 'pages/OrderDetail/components/InformationArea';
import { PatientArea } from 'pages/OrderDetail/components/Rx/PatientArea';

import { useOrderDetailQuery } from '../../api/orderQuery';
import CustomSendbirdProvider from '../../shared/hooks/Sendbird/CustomSendbirdProvider';
import ChatButton from './components/Chat/ChatButton';
import { Viewer } from './components/Viewer/Viewer';

export type ChatInfo = {
  ordererEmail: string;
  orderStatus: status;
  orderId: string;
  chattingChannelUrl: string | undefined;
  refetch: () => Promise<UseQueryResult>;
};

export default function OrderDetail() {
  const { pathname } = useLocation();
  const orderId = pathname.split('/').pop() ?? '';

  const { data, refetch, isLoading } = useOrderDetailQuery(orderId);
  const orderDetail = data?.data as OrderDetailResponse;

  const chatInfo = {
    ordererEmail: orderDetail.orderer.email,
    orderStatus: orderDetail.status,
    orderId: orderDetail.id,
    chattingChannelUrl: orderDetail.chattingChannelUrl ?? orderDetail.id,
    refetch,
  };
  console.log(chatInfo);

  return (
    <CustomSendbirdProvider>
      <SplitHalfLayout>
        {/*Case Viewer*/}
        <SplitHalfLayout.Left sxProps={{ position: 'relative' }}>
          <Viewer
            key={orderId}
            files={{
              patientFile: orderDetail.patientFilesResponse,
              designedFile: orderDetail.designedFilesResponse,
            }}
          />
          <ChatButton chatInfo={chatInfo} isLoading={isLoading} />
        </SplitHalfLayout.Left>
        {/*Order details*/}
        <SplitHalfLayout.Right
          sxProps={{
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.background.white,
          }}
        >
          {/*Common -> 기본정보*/}
          <PatientArea orderDetail={orderDetail} />
          <Stack sx={{ minHeight: '16px', backgroundColor: theme.palette.grey['100'] }} />
          <ActionArea orderDetail={orderDetail} />
          <Stack sx={{ minHeight: '16px', backgroundColor: theme.palette.grey['100'] }} />
          <InformationArea orderDetail={orderDetail} />
        </SplitHalfLayout.Right>
      </SplitHalfLayout>
    </CustomSendbirdProvider>
  );
}
