import React from 'react';
import { SearchField } from 'pages/MyOrderList/components/filter/SearchField';
import { SearchTarget } from 'pages/MyOrderList/components/filter/SearchTarget';
import { OrderRole } from 'shared/types/role';
import { SubListHeaderDetailType } from 'shared/types/subHeader';
import DateRangePicker from 'shared/ui/DateRangePicker/DateRangePicker';

export type AllowedFilterStrings = 'selectSearchTarget' | 'dateSelection' | 'searchField';

export const ORDER_PAGE_INFO: Record<OrderRole, SubListHeaderDetailType> = {
  orderer: {
    title: 'subheader_my_orders.title', // i18n key
    titleNotice: 'my_orders_tooltip.body',
    filterComponents: ['dateSelection', 'searchField'],
  },
  receiver: {
    title: 'subheader_received_orders.title',
    titleNotice: 'my_orders_tooltip.body',
    filterComponents: ['dateSelection', 'selectSearchTarget', 'searchField'],
  },
};

export const FILTER_COMPONENT_MAP: Record<AllowedFilterStrings, React.ComponentType<any> | null> = {
  dateSelection: DateRangePicker,
  selectSearchTarget: SearchTarget,
  searchField: SearchField,
};
