import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { SwitchCase } from 'pages/MyOrderList/components/SwitchCase';
import { SubListHeaderDetailType } from 'shared/types/subHeader';
import { NoticeTooltip } from 'shared/ui/NoticeTooltip/NoticeTooltip';

const SubHeader = ({ subHeaderInfo }: { subHeaderInfo: SubListHeaderDetailType }) => {
  const { t } = useTranslation();
  const { title, titleNotice, filterComponents, children } = subHeaderInfo;

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '100%',
        height: '140px',
        p: '40px 40px 52px 48px',
        justifyContent: 'space-between',
      }}
    >
      <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
        <Typography variant={'H36'}>{t(title)}</Typography>
        {titleNotice && <NoticeTooltip description={titleNotice} />}
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '12px' }}>
        {filterComponents.map((filterComponent) => (
          <SwitchCase key={filterComponent} value={filterComponent} />
        ))}
      </Stack>
      {children}
    </Stack>
  );
};

export default SubHeader;
