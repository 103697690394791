import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Ricon, theme } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';

export const PreviewImage = ({ previewUrl, onDelete }: { previewUrl: string; onDelete?: () => void }) => {
  const { t } = useTranslation();
  //onDelete 없을 경우 view only
  return (
    <Stack
      sx={{
        position: 'relative',
        '&:hover .overlay': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
        '&:hover .delete-button': {
          visibility: 'visible',
        },
      }}
    >
      <Box
        className={onDelete ? 'image-box' : ''}
        component="img"
        src={previewUrl}
        alt="Selected file preview"
        sx={{
          width: '100%',
          height: '154px',
          borderRadius: '8px',
          objectFit: 'cover',
        }}
      />
      <Box
        className="overlay"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent', // 기본값은 투명
          transition: 'background-color 0.3s ease',
          borderRadius: '8px',
        }}
      />
      {onDelete && (
        <Button
          className={'delete-button'}
          size={'36'}
          color={'grey'}
          variant={'outlined'}
          onClick={onDelete}
          endIcon={<Ricon icon={'ri-delete-bin-6-fill'} svgProps={{ width: 18, height: 18 }} />}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            visibility: 'hidden',
            '&:hover': { cursor: 'pointer', backgroundColor: theme.palette.grey['100'] },
          }}
        >
          {t('my_info-organization-delete_business_license.delete')}
        </Button>
      )}
    </Stack>
  );
};
