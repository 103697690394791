import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useOrderState } from 'pages/MyOrderList/atoms/useOrderState';

import { getCurrentOrderStatus } from '../utils/getOrderStatusConfig';

export type UseDateRangePicker = ReturnType<typeof useDateRangePicker>;

export const useDateRangePicker = () => {
  const currentOrderSatatus = getCurrentOrderStatus();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [dateFilterType, setDateFilterType] = useState('all');
  const [prevDateFilterType, setPrevDateFilterType] = useState('all');
  const [selectedDate, setSelectedDate] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [selectedCustomDate, setSelectedCustomDate] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [isEnableCustomData, setIsEnableCustomData] = useState<boolean>(false);
  const [isOpenStartDate, setIsOpenStartDate] = useState<boolean>(!selectedDate.startDate);
  const [isOpenEndDate, setIsOpenEndDate] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDate({
      startDate: null,
      endDate: null,
    });
    setDateFilterType('all');
  }, [currentOrderSatatus]);

  const isCustomDatePickerOpen = dateFilterType === 'custom';
  useEffect(() => {
    setIsEnableCustomData(!!selectedCustomDate.startDate && !!selectedCustomDate.endDate);
  }, [selectedCustomDate]);
  const { updateOrderQueryParams } = useOrderState();

  interface IDateRangePickerReturn {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }
  const handleDateChange = (value: IDateRangePickerReturn) => {
    updateOrderQueryParams({
      startDate: value?.startDate ? dayjs(value.startDate).startOf('day').toISOString() : undefined,
      endDate: value?.endDate ? dayjs(value.endDate).add(1, 'day').startOf('day').toISOString() : undefined,
    });
  };
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleDatePickerClick = (dateType: string) => {
    if (dateType === 'custom') {
      setDateFilterType(dateType);
      return;
    }
    const newSelectedDate = (() => {
      switch (dateType) {
        case 'today':
          return {
            startDate: dayjs().startOf('day'),
            endDate: dayjs().endOf('day'),
          };
        case 'last_7_days':
          return {
            startDate: dayjs().subtract(6, 'days').startOf('day'),
            endDate: dayjs().endOf('day'),
          };
        case 'last_30_days':
          return {
            startDate: dayjs().subtract(29, 'days').startOf('day'),
            endDate: dayjs().endOf('day'),
          };
        case 'all':
          return {
            startDate: null,
            endDate: null,
          };
        default:
          return {
            startDate: null,
            endDate: null,
          };
      }
    })();
    setDateFilterType(dateType);
    setPrevDateFilterType(dateType);
    setSelectedDate(newSelectedDate);
    handleDateChange(newSelectedDate);
    setSelectedCustomDate({
      startDate: null,
      endDate: null,
    });
    setAnchorEl(null);
  };

  const handleCustomStartDateChange = (date: Dayjs) => {
    const newStartDate = date;
    let newEndDate: Dayjs | null = selectedCustomDate.endDate;

    if (newEndDate && newStartDate) {
      if (newEndDate?.isBefore(newStartDate, 'day')) {
        newEndDate = null;
      }
    }

    setSelectedCustomDate({ startDate: newStartDate, endDate: newEndDate });
    setIsOpenEndDate(true);
  };

  const handleCustomEndDateChange = (date: Dayjs) => {
    let newStartDate: Dayjs | null = selectedCustomDate.startDate;
    let newEndDate: Dayjs | null = date;

    if (newStartDate && newEndDate) {
      if (newEndDate?.isBefore(newStartDate, 'day')) {
        newStartDate = newEndDate;
        newEndDate = null;
      }
    }

    setSelectedCustomDate({ startDate: newStartDate, endDate: newEndDate });
    setIsOpenEndDate(false);
  };

  const handleCustomDateApplyClick = () => {
    if (!Object.values(selectedCustomDate).some((item) => item === null)) {
      setSelectedDate(selectedCustomDate);
      setAnchorEl(null);
      setPrevDateFilterType('custom');
      handleDateChange(selectedCustomDate);

      setIsOpenStartDate(false);
      setIsOpenEndDate(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (dateFilterType === 'custom') {
      setSelectedCustomDate(selectedDate);
      setIsOpenStartDate(!selectedDate.startDate);
      setIsOpenEndDate(false);

      setDateFilterType(prevDateFilterType);
      if (prevDateFilterType !== 'custom') {
        setSelectedCustomDate({ startDate: null, endDate: null });
        setIsOpenStartDate(true);
      }
    }
  };

  const chipLabel = (() => {
    if (isCustomDatePickerOpen && prevDateFilterType !== 'custom') {
      return t('date_filter_variant.custom');
    } else if (selectedDate.startDate?.isSame(selectedDate.endDate, 'day')) {
      return `${selectedDate.startDate?.format('YYYY.MM.DD.')}`;
    } else {
      return `${selectedDate.startDate?.format('YYYY.MM.DD.')} ~ ${selectedDate.endDate?.format('YYYY.MM.DD.')}`;
    }
  })();

  const isDateFilterOpen = Boolean(anchorEl);

  return {
    anchorEl,
    chipLabel,
    dateFilterType,
    handleButtonClick,
    handleClose,
    handleCustomDateApplyClick,
    handleCustomEndDateChange,
    handleCustomStartDateChange,
    handleDatePickerClick,
    isCustomDatePickerOpen,
    isDateFilterOpen,
    selectedCustomDate,
    selectedDate,
    isEnableCustomData,
    isOpenEndDate,
    isOpenStartDate,
  };
};
