import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Chip,
  DatePicker,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Ricon,
  Typography,
} from '@imago-cloud/design-system';
import { blue, grey } from '@imago-cloud/design-system/lib/esm/colors';
import { IconButtonProps } from '@imago-cloud/design-system/lib/esm/Components/Inputs/IconButton/IconButton';
import { Box, Stack } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useDateRangePicker } from 'shared/hooks/useDateRangePicker';

const MENU_LIST = [
  { title: 'date_filter_variant.all', value: 'all' },
  { title: 'date_filter_variant.today', value: 'today' },
  { title: 'date_filter_variant.last_7_days', value: 'last_7_days' },
  { title: 'date_filter_variant.last_30_days', value: 'last_30_days' },
];

export interface IDateRangePickerReturn {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export interface DateRangePickerProps {
  handleDateChange: (value: IDateRangePickerReturn, callbackReset?: (all: 'all') => void) => void;
  size?: IconButtonProps['size'];
}

const DateRangePicker = () => {
  const { t } = useTranslation();
  const {
    handleDatePickerClick,
    selectedCustomDate,
    handleCustomStartDateChange,
    handleCustomEndDateChange,
    handleCustomDateApplyClick,
    isDateFilterOpen,
    dateFilterType,
    isCustomDatePickerOpen,
    anchorEl,
    handleButtonClick,
    handleClose,
    chipLabel,
    isEnableCustomData,
    isOpenEndDate,
    isOpenStartDate,
  } = useDateRangePicker();

  return (
    <Stack direction="row" alignItems="center">
      {(dateFilterType !== 'all' || isCustomDatePickerOpen) && (
        <Chip label={chipLabel} sx={{ mr: '8px' }} onDelete={() => handleDatePickerClick('all')} data-cy="dateLabel" />
      )}
      <IconButton variant="outlined" size="48" onClick={handleButtonClick} data-cy="datePickerButton">
        <Ricon
          icon={dateFilterType === 'all' ? 'ri-calendar-line' : 'ri-calendar-check-line'}
          svgProps={{
            fill: dateFilterType === 'all' ? grey[800] : blue.main,
          }}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={isDateFilterOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          mt: '8px',
        }}
        PaperProps={{
          sx: {
            width: '200px !important',
            padding: '8px !important',
          },
        }}
      >
        {MENU_LIST.map(({ title, value }, index) => (
          <MenuItem
            key={title}
            onClick={() => handleDatePickerClick(value)}
            selected={dateFilterType === value && !isCustomDatePickerOpen}
            data-cy={`date-${value}`}
          >
            {t(title)}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key="custom" onClick={() => handleDatePickerClick('custom')} selected={isCustomDatePickerOpen}>
          {t('date_filter_variant.custom')}
        </MenuItem>
        {isCustomDatePickerOpen && (
          <Stack>
            <Stack direction="row" sx={{ p: '16px 10px' }} alignItems="flex-end">
              <Box sx={{ width: '20px', height: '20px', mb: '6px' }} alignItems="center">
                <Typography variant="Body16">~</Typography>
              </Box>
              <Stack gap={'10px'}>
                <DatePicker
                  open={isOpenStartDate}
                  value={selectedCustomDate.startDate}
                  placeholder={t('date_filter_variant.start_date') as string}
                  onAccept={(value) => handleCustomStartDateChange(value as Dayjs)}
                  inputFormat={'YYYY.MM.DD'}
                  disableFuture
                  disableHighlightToday
                />
                <DatePicker
                  open={isOpenEndDate}
                  value={selectedCustomDate.endDate}
                  placeholder={t('date_filter_variant.end_date') as string}
                  // onChange={(value) => handleCustomEndDateChange(value as Dayjs)}
                  onAccept={(value) => handleCustomEndDateChange(value as Dayjs)}
                  inputFormat={'YYYY.MM.DD'}
                  disableFuture
                  disableHighlightToday
                />
              </Stack>
            </Stack>
            <Button
              size="36"
              variant="text"
              color="blue"
              sx={{ width: 'fit-content', alignSelf: 'flex-end', mb: '10px', mr: '10px' }}
              onClick={handleCustomDateApplyClick}
              disabled={!isEnableCustomData}
            >
              {t('date_filter_variant.btn_apply')}
            </Button>
          </Stack>
        )}
      </Popover>
    </Stack>
  );
};

export default DateRangePicker;
