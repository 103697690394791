import React, { useEffect } from 'react';
import { theme, Typography } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { OrderResponse, PartnerLabOrderResponse, status } from 'client/types.gen';
import { useOrderState } from 'pages/MyOrderList/atoms/useOrderState';
import SubHeader from 'pages/MyOrderList/components/SubHeader';
import { Chat } from 'shared/ui';
import { ListBodyWrapper } from 'shared/ui/list/ListBodyWrapper';
import { ListHead } from 'shared/ui/list/ListHead';
import { ToothInfoLabel } from 'shared/ui/list/ToothInfoLabel';
import { OrderStatusLabel } from 'shared/ui/orderStatusLabel/OrderStatusLabel';
import { getFormatDateWithTime } from 'shared/utils/formatDate';
import { getOrderStatusConfig } from 'shared/utils/getOrderStatusConfig';

import CustomSendbirdProvider from '../../shared/hooks/Sendbird/CustomSendbirdProvider';

export default function MyOrderList() {
  const { orderListHeaderConfig, orderPageConfig } = getOrderStatusConfig();

  const { resetOrderQueryParams } = useOrderState();

  useEffect(() => {
    return () => {
      resetOrderQueryParams();
    };
  }, []);

  return (
    <Stack>
      <SubHeader subHeaderInfo={orderPageConfig} />
      <Stack sx={{ height: 'calc(100vh - 184px)', overflowX: 'scroll' }}>
        <ListHead headDetail={orderListHeaderConfig} gap={'60px'} />
        <ListBodyWrapper renderCustomContent={renderContent} />
      </Stack>
      <CustomSendbirdProvider>
        <Chat />
      </CustomSendbirdProvider>
    </Stack>
  );
}

const renderContent = (order: OrderResponse | PartnerLabOrderResponse, dateType: string) => {
  if ('organizationName' in order) {
    // PartnerLabOrderResponse 타입
    const { orderId, patientName, orderItems, status, isRemake, organizationName, statusUpdatedAt, orderedAt } = order;

    return (
      <>
        <Box
          sx={{
            flex: '1 1 30%',
            minWidth: '224px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant={'Body18'}>{patientName}</Typography>
        </Box>
        <Box
          sx={{
            flex: '1 1 30%',
            minWidth: '242px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant={'Body18'}>{organizationName}</Typography>
        </Box>

        <Box sx={{ flex: '1 1 20%', minWidth: '330px' }}>
          <ToothInfoLabel orderItems={orderItems} />
        </Box>
        <Box sx={{ flex: '1 1 15%', minWidth: '180px', pl: '12px' }}>
          <OrderStatusLabel orderStatus={status as status} />
        </Box>
        <Box sx={{ flex: '1 1 15%', minWidth: '90px' }}>{isRemake && <OrderStatusLabel orderStatus={'REMAKE'} />}</Box>

        <Box sx={{ flex: '1 1 15%', minWidth: '220px', pl: '7px' }}>
          <Typography
            variant={'Body18'}
            sx={{
              color: theme.palette.text.thirdary,
              whiteSpace: 'nowrap',
            }}
          >
            {getFormatDateWithTime(dateType === 'statusUpdatedAt' ? statusUpdatedAt : orderedAt ?? new Date())}
          </Typography>
        </Box>
      </>
    );
  } else {
    // OrderResponse 타입
    const { orderId, patientName, orderItems, status, isRemake, orderedAt, statusUpdatedAt } = order;

    return (
      <>
        <Box
          sx={{
            flex: '1 1 30%',
            minWidth: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant={'Body18'}>{patientName}</Typography>
        </Box>

        <Box sx={{ flex: '1 1 30%', minWidth: '330px' }}>
          <ToothInfoLabel orderItems={orderItems} />
        </Box>
        <Box sx={{ flex: '1 1 15%', minWidth: '180px', pl: '12px' }}>
          <OrderStatusLabel orderStatus={status as status} />
        </Box>
        <Box sx={{ flex: '1 1 15%', minWidth: '90px' }}>{isRemake && <OrderStatusLabel orderStatus={'REMAKE'} />}</Box>

        <Box sx={{ flex: '1 1 15%', minWidth: '220px', pl: '7px' }}>
          <Typography
            variant={'Body18'}
            sx={{
              color: theme.palette.text.thirdary,
              whiteSpace: 'nowrap',
            }}
          >
            {getFormatDateWithTime(dateType === 'statusUpdatedAt' ? statusUpdatedAt : orderedAt ?? new Date())}
          </Typography>
        </Box>
      </>
    );
  }
};
