import { UseQueryResult } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from '@imago-cloud/design-system';

import { useUserQuery } from '../../../../api/userQuery';
import { ChatIcon } from '../../../../assets';
import { status } from '../../../../client';
import { useUnreadChannelMessageCount } from '../../../../shared/hooks/Sendbird/useCreateChannel';
import { ChatInfo } from '../../OrderDetail';
import ViewerChatWindow from './ViewerChatWindow';

const userChatAvailableOrderStatus = [
  'ORDERED',
  'DESIGNING',
  'WAITING_DESIGN_CONFIRMATION',
  'MANUFACTURING',
  'SHIPPED',
  'COMPLETED',
];

const partnerLabChatAvailableOrderStatus = [
  'DESIGNING',
  'WAITING_DESIGN_CONFIRMATION',
  'MANUFACTURING',
  'SHIPPED',
  'COMPLETED',
];

const checkChatAvailability = (userEmail: string, ordererEmail: string, orderStatus: status) => {
  const amIOrderer = userEmail === ordererEmail;
  // 파트너랩도 오더 가능: 진입할 수 있다면 assign 된 것.
  const amIPartnerLab = userEmail !== ordererEmail;
  if (amIOrderer && userChatAvailableOrderStatus.includes(orderStatus)) {
    return true;
  } else if (amIPartnerLab && partnerLabChatAvailableOrderStatus.includes(orderStatus)) {
    return true;
  }
  return false;
};

interface ChatButtonProps {
  chatInfo: ChatInfo;
  isLoading: boolean;
}
export default function ChatButton({ chatInfo, isLoading }: ChatButtonProps) {
  const {
    data: {
      data: { email },
    },
  } = useUserQuery();

  const { t } = useTranslation();
  const isChatAvailable = checkChatAvailability(email, chatInfo.ordererEmail, chatInfo.orderStatus);

  const { unreadMessageCount, getUnreadMessageCount } = useUnreadChannelMessageCount(
    isChatAvailable ? (chatInfo?.chattingChannelUrl as string) : '',
  );
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatClick = async () => {
    setIsChatOpen((prev) => !prev);
    await getUnreadMessageCount();
  };

  return (
    <>
      <Badge
        badgeContent={unreadMessageCount}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          position: 'absolute',
          top: '40px',
          right: '20px',
          zIndex: 0,
        }}
      >
        <Button
          onClick={handleChatClick}
          size="60"
          color="grey"
          variant="outlined"
          startIcon={<ChatIcon />}
          loading={isLoading}
        >
          {t('chat.messages')}
        </Button>
      </Badge>
      <ViewerChatWindow
        chatInfo={chatInfo}
        onClose={handleChatClick}
        isOpen={!!chatInfo.chattingChannelUrl && isChatOpen}
      />
    </>
  );
}
