import { GetOrders1Data, GetOrdersData, OrderResponse } from 'client/types.gen';

export type StatusType = NonNullable<GetOrdersData['filteredStatus']>[number];

export type StatusFilterOptionType = {
  label: string;
  value: StatusType;
};

export const STATUS_FILTER_OPTIONS: StatusFilterOptionType[] = [
  { label: 'order_list.new_order', value: 'ORDERED' },
  { label: 'order_list.confirmation_required', value: 'WAITING_DESIGN_CONFIRMATION' },
  { label: 'order_list.designing', value: 'DESIGNING' },
  { label: 'order_list.manufacturing', value: 'MANUFACTURING' },
  { label: 'order_list.rejected', value: 'REJECTED' },
  { label: 'order_list.shipped', value: 'SHIPPED' },
  { label: 'order_list.completed', value: 'COMPLETED' },
  { label: 'order_list.canceled', value: 'CANCELED' },
];

export const STATUS_LABELS: Partial<Record<OrderResponse['status'], string>> = {
  ORDERED: 'order_list.new_order',
  DESIGNING: 'order_list.designing',
  WAITING_DESIGN_CONFIRMATION: 'order_list.confirmation_required',
  REJECTED: 'order_list.rejected',
  SHIPPED: 'order_list.shipped',
  COMPLETED: 'order_list.completed',
  MANUFACTURING: 'order_list.manufacturing',
  CANCELED: 'order_list.canceled',
};

export const DATE_TYPE_OPTIONS = [
  { label: 'list_header_my_orders.modified', value: 'statusUpdatedAt' },
  { label: 'list_header_my_orders.created', value: 'createdAt' },
];
export const SEARCH_MENU_ITEMS: { label: string; value: GetOrders1Data['searchFilterType'] }[] = [
  { label: 'list_header.patient_name', value: 'PATIENT_NAME' },
  { label: 'list_header.organization_name', value: 'ORGANIZATION_NAME' },
];
