import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, IconButton, Ricon, theme, Tooltip, Typography } from '@imago-cloud/design-system';
import { Grid, Stack, SxProps, useMediaQuery } from '@mui/material';

export default function Dashboard() {
  const isMobile = useMediaQuery('(max-width: 1280px)');

  return (
    <Stack sx={{ py: '44px', px: '60px', gap: '28px' }}>
      <Dashboard.Header />
      <Grid
        container
        sx={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr 1fr' : '2fr 1fr',
          gap: '20px',

          gridTemplateAreas: isMobile
            ? `
                "summary prosthesis"
                "monthly prosthesis"
                "yearly yearly"
              `
            : `
                "summary monthly"
                "yearly prosthesis"
               
              `,
        }}
      >
        <Grid item sx={{ gridArea: 'summary' }}>
          <OrderSummary />
        </Grid>

        <Grid item sx={{ gridArea: 'monthly' }}>
          <MonthlyStatistics />
        </Grid>

        <Grid item sx={{ gridArea: 'yearly', mt: isMobile ? 0 : '-178px' }}>
          <YearlyStatistics />
        </Grid>

        <Grid item sx={{ gridArea: 'prosthesis' }}>
          <ProsthesisStatistics />
        </Grid>
      </Grid>
    </Stack>
  );
}

const Header = () => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Stack sx={{ flexDirection: 'row', gap: '12px', height: 'fit-content' }}>
        <Avatar src={undefined} size={32} />
        <Stack sx={{ flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
          <Typography variant={'Body18'}>{t('dashboard.welcome')}</Typography>
          <Typography variant={'Subtitle_Semibold18'}>John Doe !</Typography>
        </Stack>
      </Stack>
      <Button
        startIcon={<Ricon icon="ri-download-2-fill" svgProps={{ fill: theme.palette.primary.main }} />}
        size={'48'}
        variant={'outlined'}
      >
        {t('dashboard.btn_export')}
      </Button>
    </Stack>
  );
};
const OrderSummary = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1280px)');

  const ORDER_SUMMARY = [
    { label: 'dashboard.ordered', value: 'ordered' },
    { label: 'dashboard.processing', value: 'processing' },
    { label: 'dashboard.shipped', value: 'shipped' },
    { label: 'dashboard.completed', value: 'completed' },
  ];

  return (
    <Grid
      container
      sx={{
        display: 'grid',
        gap: '20px',
        width: '100%',
        gridTemplateColumns: isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
      }}
    >
      {ORDER_SUMMARY.map(({ label }) => (
        <CardLayout key={label} sx={{ px: '24px', py: '20px', gap: '6px' }}>
          <Typography variant={'Subtitle_Semibold16'} sx={{ color: theme.palette.text.thirdary }}>
            {t(label)}
          </Typography>
          <Typography variant={'H36'}>0</Typography>
        </CardLayout>
      ))}
    </Grid>
  );
};

const MonthlyStatistics = () => {
  const { t } = useTranslation();
  return (
    <CardLayout sx={{ p: '24px', height: '296px', gap: '16px' }}>
      <Stack>
        <Typography variant={'H24'}>{t('dashboard.cases_so_far', { count: 0 })}</Typography>
        <Typography variant={'Body16'}>{t('dashboard.more_cases_than_this_time', { count: 0 })}</Typography>
      </Stack>
      {/*그래프*/}
      {/*범례*/}
    </CardLayout>
  );
};

const YearlyStatistics = () => {
  const { t } = useTranslation();
  const description = t('dashboard_tooltip.body').split('\n');
  return (
    <CardLayout sx={{ height: '752px', p: '24px 40px 40px 24px', position: 'relative' }}>
      <Stack sx={{ flexDirection: 'row' }}>
        <Stack sx={{ flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <Typography variant={'H18'}>{t('dashboard.statistics')}</Typography>
          <Tooltip
            title={
              <ul
                style={{
                  margin: 0,
                  listStyleType: 'none',
                  padding: '0',
                }}
              >
                {description.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            }
            placement={'right'}
            arrow
          >
            <Stack>
              <Ricon icon={'ri-question-mark'} svgProps={{ width: 18, height: 18, fill: theme.palette.grey['700'] }} />
            </Stack>
          </Tooltip>
        </Stack>
        <Stack></Stack>
      </Stack>

      <Stack
        sx={{
          flexDirection: 'row',
          position: 'absolute',
          alignItems: 'center',
          right: '20px',
          top: '20px',
          gap: '12px',
        }}
      >
        <Typography variant={'Subtitle_Semibold16'} sx={{ color: theme.palette.grey['800'] }}>
          2022.02
        </Typography>
        <Stack sx={{ flexDirection: 'row', gap: '8px' }}>
          <IconButton size={'36'} color={'grey800'} icon_size={'small'} variant={'transparent'}>
            <Ricon icon={'ri-arrow-left-s-line'} />
          </IconButton>
          <IconButton size={'36'} color={'grey800'} icon_size={'small'} variant={'transparent'}>
            <Ricon icon={'ri-arrow-right-s-line'} />
          </IconButton>
        </Stack>
      </Stack>
    </CardLayout>
  );
};
const ProsthesisStatistics = () => {
  const { t } = useTranslation();

  const mostOrderedCaseLength = 0;
  return (
    <CardLayout sx={{ p: '24px', height: '518px' }}>
      <Stack>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Stack>
            <Typography variant={'H24'}>{t('dashboard.title_most_ordered_case')}</Typography>
            <Typography variant={'H24'} sx={{ color: theme.palette.primary.light }}>
              {t(`${mostOrderedCaseLength ? 'dialog_product_option.btn_crown' : 'dashboard.most_ordered_case_none'}`)}
            </Typography>
          </Stack>
          <Typography variant={'Body16'} sx={{ color: theme.palette.text.thirdary, mt: '8px' }}>
            {t('dashboard.this_month')}
          </Typography>
        </Stack>
      </Stack>
    </CardLayout>
  );
};

const CardLayout = ({ children, sx }: { children: ReactNode; sx: SxProps }) => {
  return (
    <Stack
      // item
      sx={{
        backgroundColor: theme.palette.background.white,
        borderRadius: '8px',
        boxShadow: '0px 8px 24px -4px #9199A33D, 0px 0px 1px 0px #9199A3A3',
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

Dashboard.Header = Header;
