import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@imago-cloud/design-system';

import { GetOrders1Data } from '../../../../client';
import { useOrderState } from '../../atoms/useOrderState';
import { SEARCH_MENU_ITEMS } from '../../constants/menuOptions';

export const SearchTarget = () => {
  const { t } = useTranslation();
  const { orderQueryParams, updateOrderQueryParams } = useOrderState();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateOrderQueryParams({ searchFilterType: event.target.value as GetOrders1Data['searchFilterType'] });
  };

  return (
    <Select
      sx={{ width: '220px' }}
      value={
        ('searchFilterType' in orderQueryParams && orderQueryParams?.searchFilterType) || SEARCH_MENU_ITEMS[0].value
      }
      onChange={handleChange}
    >
      {SEARCH_MENU_ITEMS.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
};
