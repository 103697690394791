import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, Loading, Ricon, Typography } from '@imago-cloud/design-system';
import { grey } from '@imago-cloud/design-system/lib/esm/colors';
import { Stack, SxProps } from '@mui/material';

import { useGetAddressUsingZipCode } from '../../../api/organization';
import { $Address, corporateNumberType } from '../../../client';
import { SchemaFieldGroup, SchemaFieldGroupProps } from '../formProvider/SchemaFieldGroup';
import { RHFTextField } from '../RHF';
import { ADDRESS_FORM, ORGANIZATION_CODE_FORM, ORGANIZATION_NAME_FORM, PHONE_NUMBER_FORM } from './organizationForm';

export type OrganizationFieldProps = {
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  sx?: SxProps;
  disabled?: boolean;
} & Partial<SchemaFieldGroupProps>;

export const OrganizationNameField = ({ onBlur, sx, disabled, titleVariant = 'H16' }: OrganizationFieldProps) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap="20px" sx={{ ...sx }}>
      <Typography variant={titleVariant}>{t(ORGANIZATION_NAME_FORM['title'])}</Typography>
      <RHFTextField
        label={ORGANIZATION_NAME_FORM['name'].label}
        name={ORGANIZATION_NAME_FORM['name'].name}
        inputProps={ORGANIZATION_NAME_FORM['name'].inputProps}
        rules={{
          ...ORGANIZATION_NAME_FORM['name'].rules,
          required: t(ORGANIZATION_NAME_FORM['name'].rules.required),
        }}
        label_visible={'none'}
        onBlur={onBlur}
        disabled={disabled}
      />
    </Stack>
  );
};

export const OrganizationAddressField = ({
  onBlur,
  disabled,
  titleVariant,
  name = 'address',
}: OrganizationFieldProps) => {
  const { t } = useTranslation();

  const { watch, setError, setValue } = useFormContext();
  const { mutateAsync, isPending } = useGetAddressUsingZipCode();
  const reorderedAddressSchema = {
    ...$Address,
    properties: {
      zipCode: $Address.properties.zipCode,
      fullAddress: $Address.properties.fullAddress,
      detailAddress: $Address.properties.detailAddress,
    },
  };

  const handleZipCodeSearch = async () => {
    const response = await mutateAsync(watch(`${name}.zipCode`));
    if (response.code === 200) {
      setValue(`${name}.fullAddress`, response.data.fullAddress);
    } else {
      setError(`${name}.zipCode`, {
        message: response.message,
      });
      setValue(`${name}.fullAddress`, '');
    }
  };

  return (
    <SchemaFieldGroup
      title={t(ADDRESS_FORM['title'])}
      schema={reorderedAddressSchema}
      name={name}
      disabled={disabled}
      titleVariant={titleVariant}
      overrideProps={{
        fullAddress: {
          label: ADDRESS_FORM['fullAddress'].label,
          onBlur,
          inputProps: ADDRESS_FORM['fullAddress'].inputProps,
          rules: ADDRESS_FORM['fullAddress'].rules,
        },
        detailAddress: {
          label: ADDRESS_FORM['detailAddress'].label,
          onBlur,
          inputProps: ADDRESS_FORM['detailAddress'].inputProps,
          rules: ADDRESS_FORM['detailAddress'].rules,
        },
        zipCode: {
          label: ADDRESS_FORM['zipCode'].label,
          inputProps: ADDRESS_FORM['zipCode'].inputProps,
          rules: ADDRESS_FORM['zipCode'].rules,
          onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleZipCodeSearch();
            }
          },
          InputProps: disabled
            ? {}
            : {
                endAdornment: isPending ? (
                  <IconButton size="24" variant="transparent" data-cy="searchLoading">
                    <Loading type="basic" size="14" />
                  </IconButton>
                ) : (
                  <IconButton size="36" variant="transparent" data-cy="submitSearch" onClick={handleZipCodeSearch}>
                    <Ricon
                      icon="ri-search-line"
                      svgProps={{
                        fill: grey[800],
                        width: 24,
                        height: 24,
                      }}
                    />
                  </IconButton>
                ),
              },
        },
      }}
    />
  );
};

export const OrganizationPhoneNumberField = ({
  onBlur,
  disabled,
  titleVariant = 'H16',
  name,
}: OrganizationFieldProps) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap="20px">
      <Typography variant={titleVariant}>{t(PHONE_NUMBER_FORM['title'])}</Typography>
      <RHFTextField
        name={name ? name : PHONE_NUMBER_FORM['number'].name}
        label={t(PHONE_NUMBER_FORM['number'].label) as string}
        label_visible={'none'}
        helperText={t(PHONE_NUMBER_FORM['number'].helperText) as string}
        inputProps={PHONE_NUMBER_FORM['number'].inputProps}
        rules={{
          ...PHONE_NUMBER_FORM['number'].rules,
          required: t(PHONE_NUMBER_FORM['number'].rules.required),
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
    </Stack>
  );
};

export const OrganizationOrganizationCodeField = ({
  onBlur,
  codeType,
}: OrganizationFieldProps & { codeType: corporateNumberType }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap="20px">
      <Typography variant="H16">{t(ORGANIZATION_CODE_FORM[codeType].title)}</Typography>
      <RHFTextField
        name={ORGANIZATION_CODE_FORM[codeType].name}
        label={t(ORGANIZATION_CODE_FORM[codeType].label) as string}
        label_visible={'none'}
        inputProps={ORGANIZATION_CODE_FORM[codeType].inputProps}
        rules={{
          ...ORGANIZATION_CODE_FORM[codeType].rules,
          required: t(ORGANIZATION_CODE_FORM[codeType].rules.required),
        }}
        onBlur={onBlur}
      />
    </Stack>
  );
};
