import React, { ReactElement, ReactNode } from 'react';
import { FILTER_COMPONENT_MAP } from 'shared/constants/subHeaderConfig';

type SwitchCaseProps<T extends string | number> = {
  value: T;
  // caseBy: Record<T, ReactNode>;
  componentProps?: any;
  defaultComponent?: ReactNode | null;
};

export const SwitchCase = <T extends string | number>({
  value,
  componentProps,
  defaultComponent = null,
}: SwitchCaseProps<T>) => {
  const Component = FILTER_COMPONENT_MAP[value as keyof typeof FILTER_COMPONENT_MAP];
  return Component ? <Component {...componentProps} /> : (defaultComponent as ReactElement | null);
};
